#projects {
  padding-top: 210px;
  padding-right: 80px;
  padding-bottom: 20px;
  padding-left: 80px;
  margin-top: -80px;
  padding-bottom: 200px;
}

.loading-card {
  background-color: #1a202c;
  color: #cbd5e0;
  font-size: 1.2em;
  padding: 10px 20px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 50px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

.loading-card strong {
  color: #81e6d9;
}


.projects-container {
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.projects-heading {
  font-size: 2em;
  margin-bottom: 50px;
  color: white;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
}

.project-card {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  background: #272C36;
  transition: transform 0.3s ease;
  width: auto;
}

.project-button {
  background-color: #262C36;
}

.project-card:hover {
  transform: scale(1.05);
}

.project-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.project-title {
  margin-top: 15px;
  font-size: 1.2em;
  color: white;
}

body.modal-open {
  overflow: hidden;
}

body.modal-open::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 998;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.7);
  width: 80%;
  max-width: 1200px;
  max-height: 700px;
  height: 80%;
  padding: 20px;
  border-radius: 10px;
}

.modal-open {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  display: flex;
  align-items: center;
  background-color: #171A1A;
  padding: 20px;
  border-radius: 10px;
}

.modal-info {
  flex: 1;
  padding-right: 20px;
}

.modal-info h2 {
  margin-bottom: 40px;
  color: white;
}

.modal-info p {
  margin-bottom: 40px;
  color: white;
}

.mh-about-tag ul {
  list-style: none;
  padding: 0;
  margin-bottom: 40px;
}

.mh-about-tag li {
  display: inline-block;
  margin-right: 10px;
  border: 1px solid #0D51A8;
  margin-bottom: 5px;
  border-radius: 10px;
  padding: 10px;
  color: white;
}

.mh-about-tag button {
  /* background-color: #e8e6e3; */
  background-color: black;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.mh-about-tag button:hover {
  background-color: #ccc;
}

.modal-image-container {
  flex: 1;
}

.modal-image {
  width: 700px;
  height: auto;
  border-radius: 5px;
}

.btn-fill {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-decoration: none;
  color: #fff;
  background-color: #007bff;
  font-weight: bold;
}

.btn-fill:hover {
  background-color: #0056b3;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #fff;
  font-size: 24px;
}

@media screen and (max-width: 768px) {
  .modal {
    width: 90%;
  }
}

@media screen and (max-width: 1180px) {
  .projects-container {
    margin-left: 150px;
  }
}

@media screen and (max-width: 1000px) {
  .projects-heading {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 880px) {

  .projects-container {
    margin-left: 80px;
  }

  .project-image {
    display: block;
  }

  /* .project-card {
    max-width: 300px;
  } */
}

@media screen and (max-width: 750px) {

  .projects-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .project-card {
    padding: 10px;
  }

  .modal {
    width: 75%;
  }

  .modal-info h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .modal-info p {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .mh-about-tag ul {
    margin-bottom: 10px;
  }

  .mh-about-tag li {
    display: inline-block;
    margin-right: 5px;
    border: 1px solid #0D51A8;
    margin-bottom: 5px;
    border-radius: 10px;
    padding: 5px;
    font-size: 14px;
  }

  .btn-fill {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .modal-content {
    flex-direction: column;
  }

  /* .project-image {
    max-width: 100px;
    max-height: 70px;
  } */

  .project-title {
    font-size: 12px;
  }
}


@media screen and (max-width: 550px) {

  /* .project-card {
    max-width: 250px;
  } */

  /* .project-image {
    display: block;
  } */

  .projects-container {
    margin: 0;
  }

  h1 {
    font-size: 20px !important
  }

  .project-title {
    font-size: 12px;
  }

}

@media screen and (max-width: 375px) {

  /* .project-image {
    display: block;
  } */

  /* .project-card {
    max-width: 200px !important;
    max-height: 170px;
  } */

  .projects-heading {
    font-size: 18px;
  }

  .project-title {
    font-size: 12px;
  }
}